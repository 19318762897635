










































import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import md5 from 'crypto-js/md5';

import Button from '@/shared/resources/components/buttons/Button.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import GridSpacer from '@/shared/resources/components/grid/GridSpacer.vue';
import Api from '@/core/api/Api';
import PayuSettings from '@/shared/lib/interfaces/PayuSettings';
import { checkIP } from '@/core/helpers/utils/SystemUtils';

@Component({
  components: {
    GridSpacer,
    Divider,
    Button,
  },
})
export default class CartOrderSuccessPayu extends Vue {
  /**
   * Props
   */
  @Prop() private order!: OnlineOrderCoreModel;

  /**
   * Refs
   */
  @Ref() private payuForm!: HTMLFormElement;

  /**
   * Data
   */
  private payuSettings: PayuSettings | null = null;
  private redirectTime: number = 7 * 1000;

  /**
   * Lifecycle hooks
   */
  private async created() {
    await this.getPayuSettings();
    this.setRedirectTimeout();
  }

  /**
   * Methods
   */
  private get payuOrder(): any {
    const orderData: any = {
      action: this.payuSettings?.url,
      firstName: '',
      lastName: '',
      email: this.order.email,
      posId: this.payuSettings?.posId,
      posAuthKey: this.payuSettings?.posAuthKey,
      sessionId: this.order.hash,
      orderId: this.order.id,
      amount: this.order.total * 100,
      desc: 'Zamówienie B2b - ' + this.order.number,
      clientIP: this.payuSettings?.ip,
      js: 0,
      ts: new Date().getTime(),
    };

    return {
      ...orderData,
      sig: md5(
        this.payuSettings?.posId +
        '' +
        orderData.sessionId +
        this.payuSettings?.posAuthKey +
        orderData.amount +
        orderData.desc +
        '' +
        '' +
        orderData.orderId +
        orderData.firstName +
        orderData.lastName +
        '' +
        '' +
        '' +
        '' +
        '' +
        '' +
        orderData.email +
        '' +
        '' +
        orderData.clientIP +
        orderData.ts +
        this.payuSettings?.md51,
      ),
    };
  }

  private async getPayuSettings() {
    try {
      const result: any = await new Api().get('/settings/b2b-payu');
      const ip: string = await checkIP();

      this.payuSettings = {
        ip,
        ...result.data,
      };
    } catch (ex) {
      // TODO handle error
    }
  }

  private setRedirectTimeout() {
    setTimeout(() => {
      this.payuForm.submit();
    }, this.redirectTime);
  }
}
