import axios, { AxiosRequestConfig } from 'axios';
import { prepareUrlWithQueryString } from '../helpers/utils/UrlUtils';

declare type RequestType = 'get' | 'post' | 'patch' | 'delete' | 'put';

export default class Request {
  /**
   * Send GET request
   * @param endpoint
   * @param data
   * @param config
   */
  public async get(endpoint: string, data: any = {}, config: any = {}): Promise<any> {
    return this.request('get', prepareUrlWithQueryString(endpoint, data), config);
  }

  /**
   * Send POST request
   * @param endpoint
   * @param data
   * @param config
   */
  public async post(endpoint: string, config: AxiosRequestConfig = {}): Promise<any> {
    return this.request('post', endpoint, config);
  }

  /**
   * Send PATCH request
   * @param endpoint
   * @param config
   */
  public async patch(endpoint: string, config: AxiosRequestConfig = {}): Promise<any> {
    return this.request('patch', endpoint, config);
  }

  /**
   * Send PUT request
   * @param endpoint
   * @param config
   */
  public async put(endpoint: string, config: AxiosRequestConfig = {}): Promise<any> {
    return this.request('put', endpoint, config);
  }

  /**
   * Send DELETE request
   * @param endpoint
   * @param config
   */
  public async delete(endpoint: string, config: AxiosRequestConfig = {}): Promise<any> {
    return this.request('delete', endpoint, config);
  }

  /**
   * Send request
   * @param type
   * @param url
   * @param config
   */
  private async request(
    type: RequestType,
    url: string,
    config: any = {},
  ) {

    const requestConfig = {
      type,
      url,
      ...config,
    };

    return axios.request(requestConfig);
  }
}
