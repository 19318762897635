import Request from '@/core/request/Request';
import IndexableInterface from '@/core/interfaces/IndexableInterface';

const IP_TEST_URL: string = 'https://www.cloudflare.com/cdn-cgi/trace';

/**
 * Check ip
 */
export const checkIP = async () => {
  try {
    const response = await new Request().get(IP_TEST_URL);
    const parsed = parsePlainText(response.data);

    return parsed ? parsed.ip : null;
  } catch (ex) {
    return null;
  }
};

/**
 * Parse plain text
 * @param text
 */
export const parsePlainText = (text: string): IndexableInterface => {
  return text.split('\n').reduce((result: IndexableInterface, row: string): IndexableInterface => {
    const exploded = row.split('=');

    result[exploded[0]] = exploded[1];
    return result;
  }, {});
};
